import React from "react";
import CountrySelector from "./CountrySelector";

const Hero = () => {
  const handleClick = () => {
    window.location.href =
      "https://shop.lipaworld.com/?recipientCountryCode=ZA";
  };

  return (
    <section className="py-20 relative w-full">
      <div className="container mx-auto px-4 flex flex-wrap items-center tops">
        <div className="w-full lg:w-1/2 mb-8 lg:mb-0 left-hero">
          <div className="top" data-aos="fade-right">
            <p>No Hidden Fees</p>
            <img src="/img/hero/Ellipse.png" />
            <p>100% Transparency</p>
          </div>

          <h1 className="text-4xl font-bold mb-4 intro" data-aos="fade-right">
            Your <span className="color">trusted marketplace</span> to shop,
            send and support your loved ones back home
          </h1>
          <p className="text-gray-600 mb-6 herop" data-aos="fade-right">
            Experience simple and secure transactions using convenient digital
            vouchers, top-ups and gift cards.
          </p>
          <div className="but">
            <button
              className="bg-green-600 text-white px-6 py-2 transition duration-300 hover:bg-green-900 shadow"
              data-aos="fade-right"
              onClick={handleClick}
            >
              Shop Now
            </button>
            {/* <button
              className="ml-4 text-gray-600 hover:text-gray-900 buthero"
              data-aos="fade-right"
            >
              Learn more
            </button> */}
          </div>
          <div className="payments" data-aos="fade-right">
            <img src="/img/hero/Visa.png" alt="visa" width={40} height={40} />
            <img
              src="/img/hero/Paypal.png"
              alt="paypal"
              width={40}
              height={40}
            />
            <img
              src="/img/hero/Mastercard.png"
              alt="mastercard"
              width={40}
              height={40}
            />
            <img src="/img/hero/Venmo.png" alt="venmo" width={40} height={40} />
          </div>
        </div>
        <div className="lg:mb-0 right-hero" data-aos="fade-left">
          <img
            src="/img/hero/woman2.jpg"
            alt="Happy customer using Lipaworld"
          />
        </div>
      </div>
      <CountrySelector />
    </section>
  );
};

export default Hero;
